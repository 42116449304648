var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tProType",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tProTypeForm",
          attrs: {
            model: _vm.tAskProjectForm,
            rules: _vm.tAskProjectFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "危害因素", prop: "hazardFactorId" } },
                    [
                      _c("Input", {
                        directives: [
                          {
                            name: "width",
                            rawName: "v-width",
                            value: 330,
                            expression: "330",
                          },
                        ],
                        attrs: { readonly: "" },
                        model: {
                          value: _vm.tAskProjectForm.hazardFactorName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tAskProjectForm,
                              "hazardFactorName",
                              $$v
                            )
                          },
                          expression: "tAskProjectForm.hazardFactorName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "问诊科目", prop: "askProject" } },
                    [
                      _c(
                        "Select",
                        {
                          directives: [
                            {
                              name: "width",
                              rawName: "v-width",
                              value: 330,
                              expression: "330",
                            },
                          ],
                          attrs: { multiple: "", placeholder: "请选择" },
                          model: {
                            value: _vm.tAskProjectForm.askProject,
                            callback: function ($$v) {
                              _vm.$set(_vm.tAskProjectForm, "askProject", $$v)
                            },
                            expression: "tAskProjectForm.askProject",
                          },
                        },
                        _vm._l(_vm.askProjectArr, function (item, index) {
                          return _c(
                            "Option",
                            { key: index, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.value))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "备注" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "textarea",
                          rows: 6,
                          maxlength: 50,
                          placeholder: "请输入备注",
                        },
                        model: {
                          value: _vm.tAskProjectForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.tAskProjectForm, "remark", $$v)
                          },
                          expression: "tAskProjectForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "default" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "Button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handelSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }